'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Sticky = require('@rubyapps/ruby-react-components/src/client/layout/Sticky');

var _Sticky2 = _interopRequireDefault(_Sticky);

var _Toolbar = require('material-ui/Toolbar');

var _Toolbar2 = require('@rubyapps/ruby-material-ui/Toolbar');

var _Header = require('./Header.cssModule');

var _Header2 = _interopRequireDefault(_Header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _ = require('lodash');

var Sticky__propTypeKeys = Object.keys(_Sticky2.default.propTypes);


var CONSTANTS = require('../../common/constants');
var DEFAULT_STICKY_PROPS = {
    className: CONSTANTS.COMPONENT_NAME,
    style: {
        zIndex: 10
    }
};

var actionGroupComponentNames = ["ContextMenu", "Button"];

var FieldHeader = _react2.default.createClass({
    displayName: 'FieldHeader',

    propTypes: {
        actions: PropTypes.object,
        name: PropTypes.string,
        listRoute: PropTypes.string,
        listName: PropTypes.string,
        showBreadcrumb: PropTypes.bool,
        breadcrumbPrefix: PropTypes.string,
        breadcrumbSeparator: PropTypes.string,
        stickyProps: PropTypes.object,
        applicationHeaderID: PropTypes.string,
        childrenPropsByClass: PropTypes.shape({
            Toolbar: PropTypes.object
        })
    },
    getDefaultProps: function getDefaultProps() {
        return {
            listRoute: null,
            listName: 'List',
            name: 'New',
            showBreadcrumb: true,
            stickyProps: DEFAULT_STICKY_PROPS,
            childrenPropsByClass: {}
        };
    },
    _navigateOnBreadcrumb: function _navigateOnBreadcrumb(event) {
        event.stopPropagation();
        event.preventDefault();
        //this.props.actions.navigateToPathWithOptions({ path: this.props.listRoute });
        this.props.actions.cancelEditing();
        return false;
    },

    _renderChildrenComponents: function _renderChildrenComponents(children) {
        return children.reduce(function (collector, child) {
            if (child.props.componentName != 'Hidden') {
                var _React$createElement;

                collector.push(_react2.default.createElement(
                    'div',
                    (_React$createElement = {
                        key: child.key,
                        className: _Header2.default.Toolbar_childSpacing,
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Header'
                    }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Header'), _React$createElement),
                    child
                ));
            }

            return collector;
        }, []);
    },
    _renderChildren: function _renderChildren() {
        var _React$createElement3, _React$createElement5;

        var children = this.props.children;
        var arrayNormalizedChildren = _.castArray(children).filter(_.identity);

        //# look at all children, if they belong in an action group, we group them
        var actionGroup = _.reduce(arrayNormalizedChildren, function (result, curr) {
            var isActionGroupComponent = actionGroupComponentNames.indexOf(curr.props.componentName) > -1 || _.get(curr, 'props.parent.position') == 'actionGroup';

            if (isActionGroupComponent) {
                return [].concat(curr, result);
            } else {
                return result;
            }
        }, []);

        //# get remaining children outside of the actionGroup
        var remainingChildren = arrayNormalizedChildren.filter(function (child) {
            return actionGroup.indexOf(child) === -1;
        });

        //# partition between left and right

        var _$partition = _.partition(remainingChildren, ['props.parent.position', 'left']),
            _$partition2 = _slicedToArray(_$partition, 2),
            leftChildren = _$partition2[0],
            rightChildren = _$partition2[1];

        leftChildren = _.sortBy(leftChildren, ['props.parent.index']);
        rightChildren = _.sortBy(rightChildren, ['props.parent.index']);

        var leftComponents = this._renderChildrenComponents(leftChildren);
        var rightComponents = this._renderChildrenComponents(rightChildren);
        var actionGroupComponents = _react2.default.createElement(
            _Toolbar.ToolbarGroup,
            (_React$createElement3 = {
                key: 'actionGroup',
                className: _Header2.default.ToolbarGroup,
                'data-codecept-selector-node': 'ToolbarGroup',
                'data-codecept-selector-file': 'Header'
            }, _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement3, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement3, 'data-codecept-selector-file', 'Header'), _React$createElement3),
            actionGroup.reverse().map(function (child) {
                var _React$createElement2;

                return _react2.default.createElement(
                    'div',
                    (_React$createElement2 = {
                        key: child.key,
                        className: _Header2.default.Toolbar_childSpacing,
                        'data-codecept-selector-node': 'div',
                        'data-codecept-selector-file': 'Header'
                    }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Header'), _React$createElement2),
                    child
                );
            })
        );

        var toolbarGroups = [];
        if (leftChildren.length) {
            var _React$createElement4;

            toolbarGroups.push(_react2.default.createElement(
                _Toolbar.ToolbarGroup,
                (_React$createElement4 = {
                    key: 'left-group',
                    className: _Header2.default.ToolbarGroup__left,
                    'data-codecept-selector-node': 'ToolbarGroup',
                    'data-codecept-selector-file': 'Header'
                }, _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement4, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement4, 'data-codecept-selector-file', 'Header'), _React$createElement4),
                leftComponents
            ));
        }

        toolbarGroups.push(_react2.default.createElement(
            _Toolbar.ToolbarGroup,
            (_React$createElement5 = {
                key: 'right-group',
                className: _Header2.default.ToolbarGroup_rest,
                lastChild: true,
                'data-codecept-selector-node': 'ToolbarGroup',
                'data-codecept-selector-file': 'Header'
            }, _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement5, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement5, 'data-codecept-selector-file', 'Header'), _React$createElement5),
            [].concat(rightComponents, actionGroupComponents)
        ));

        return toolbarGroups;
    },
    render: function render() {
        var _React$createElement6,
            _React$createElement7,
            _React$createElement8,
            _React$createElement9,
            _React$createElement10,
            _React$createElement11,
            _React$createElement12,
            _React$createElement13,
            _this = this,
            _extends2,
            _extends3;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var allStickyProps = _.pick(this.props, Sticky__propTypeKeys);
        var shallowStickyPropsWithDefaults = _extends({}, DEFAULT_STICKY_PROPS, this.props.stickyProps);

        //# NOTE: it's ok to be passing a node to the <ToolbarTitle>
        return _react2.default.createElement(
            _Sticky2.default,
            _extends({}, allStickyProps, (_extends3 = { stickyProps: shallowStickyPropsWithDefaults, 'data-codecept-selector-node': 'Sticky',
                'data-codecept-selector-file': 'Header'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Sticky'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Header'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Sticky'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Header'), _extends3)),
            _react2.default.createElement(
                _Toolbar.Toolbar,
                _extends({
                    className: _Header2.default.Toolbar
                }, _.get(this.props, ['childrenPropsByClass', 'Toolbar'], {}), (_extends2 = {
                    'data-codecept-selector-node': 'Toolbar',
                    'data-codecept-selector-file': 'Header'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Header'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Toolbar'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Header'), _extends2)),
                this.props.showBreadcrumb ? _react2.default.createElement(
                    _Toolbar.ToolbarGroup,
                    (_React$createElement12 = {
                        className: _Header2.default.ToolbarGroup,
                        'data-codecept-selector-node': 'ToolbarGroup',
                        'data-codecept-selector-file': 'Header'
                    }, _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement12, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement12, 'data-codecept-selector-file', 'Header'), _React$createElement12),
                    _react2.default.createElement(_Toolbar2.ToolbarTitle, (_React$createElement11 = {
                        className: _Header2.default.ToolbarTitle_mainLabel,
                        text: _react2.default.createElement(
                            'span',
                            (_React$createElement10 = {
                                className: _Header2.default.wrappingSpan,
                                'data-codecept-selector-node': 'span',
                                'data-codecept-selector-file': 'Header'
                            }, _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement10, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement10, 'data-codecept-selector-file', 'Header'), _React$createElement10),
                            _react2.default.createElement(
                                'span',
                                (_React$createElement6 = {
                                    className: _Header2.default.ToolbarTitle_Edit,
                                    'data-codecept-selector-node': 'span',
                                    'data-codecept-selector-file': 'Header'
                                }, _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement6, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement6, 'data-codecept-selector-file', 'Header'), _React$createElement6),
                                this.props.breadcrumbPrefix
                            ),
                            _react2.default.createElement(
                                'a',
                                (_React$createElement7 = { href: this.props.listRoute,
                                    className: _Header2.default.ToolbarTitle_ParentLink,
                                    onClick: this._navigateOnBreadcrumb,
                                    'data-codecept-selector-node': 'a',
                                    'data-codecept-selector-file': 'Header'
                                }, _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement7, 'data-codecept-selector-node', 'a'), _defineProperty(_React$createElement7, 'data-codecept-selector-file', 'Header'), _React$createElement7),
                                this.props.listName
                            ),
                            _react2.default.createElement('span', (_React$createElement8 = { dangerouslySetInnerHTML: { __html: this.props.breadcrumbSeparator }, 'data-codecept-selector-node': 'span',
                                'data-codecept-selector-file': 'Header'
                            }, _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement8, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement8, 'data-codecept-selector-file', 'Header'), _React$createElement8)),
                            _react2.default.createElement(
                                'span',
                                (_React$createElement9 = {
                                    className: _Header2.default.ToolbarTitle_span,
                                    'data-codecept-selector-node': 'span',
                                    'data-codecept-selector-file': 'Header'
                                }, _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement9, 'data-codecept-selector-node', 'span'), _defineProperty(_React$createElement9, 'data-codecept-selector-file', 'Header'), _React$createElement9),
                                this.props.name || 'New'
                            )
                        ),
                        'data-codecept-selector-node': 'ToolbarTitle',
                        'data-codecept-selector-file': 'Header'
                    }, _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement11, 'data-codecept-selector-node', 'ToolbarTitle'), _defineProperty(_React$createElement11, 'data-codecept-selector-file', 'Header'), _React$createElement11))
                ) : _react2.default.createElement(_Toolbar.ToolbarGroup, (_React$createElement13 = {
                    className: _Header2.default.ToolbarGroup,
                    'data-codecept-selector-node': 'ToolbarGroup',
                    'data-codecept-selector-file': 'Header'
                }, _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'Header'), _defineProperty(_React$createElement13, 'data-codecept-selector-node', 'ToolbarGroup'), _defineProperty(_React$createElement13, 'data-codecept-selector-file', 'Header'), _React$createElement13)),
                function () {
                    if (_this.props.children) {
                        return _this._renderChildren();
                    }
                }()
            )
        );
    }

});

exports.default = FieldHeader;