'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _transitions = require('material-ui/styles/transitions');

var _transitions2 = _interopRequireDefault(_transitions);

var _Toggle = require('@rubyapps/ruby-material-ui/Toggle');

var _Toggle2 = _interopRequireDefault(_Toggle);

var _Checkbox = require('material-ui/Checkbox');

var _Checkbox2 = _interopRequireDefault(_Checkbox);

var _addInfoModeProps = require('@rubyapps/ruby-react-components/src/client/hoc/addInfoModeProps');

var _addInfoModeProps2 = _interopRequireDefault(_addInfoModeProps);

var _Toggle_style = require('./Toggle_style');

var _Toggle_style2 = _interopRequireDefault(_Toggle_style);

var _Toggle3 = require('./Toggle.cssModule');

var _Toggle4 = _interopRequireDefault(_Toggle3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var PropTypes = _react2.default.PropTypes;

var _require = require('@rubyapps/ruby-field-common'),
    Label = _require.Label,
    RichErrorMessages = _require.RichErrorMessages;

var getStyles = function getStyles(props, context, state) {
    var _context$muiTheme = context.muiTheme,
        baseTheme = _context$muiTheme.baseTheme,
        errorColor = _context$muiTheme.textField.errorColor,
        toggle = _context$muiTheme.toggle;


    var styles = {
        error: {
            position: 'relative',
            bottom: -6,
            fontSize: 12,
            lineHeight: '12px',
            color: errorColor,
            transition: _transitions2.default.easeOut()
        },
        label: {
            color: props.disabled ? toggle.labelDisabledColor : toggle.labelColor
        }
    };

    _extends(styles.error, props.errorStyle);

    return styles;
};
var FieldToggle = _react2.default.createClass({
    displayName: 'FieldToggle',

    propTypes: {
        label: PropTypes.string,
        fieldKey: PropTypes.string,
        value: PropTypes.bool,
        error: PropTypes.shape({
            message: PropTypes.string
        }),
        id: PropTypes.string,
        actions: PropTypes.object,
        componentType: PropTypes.oneOf(['Toggle', 'Checkbox'])
    },
    contextTypes: {
        muiTheme: PropTypes.object
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: null
        };
    },
    _onValueChange: function _onValueChange(event, toggled) {
        this.props.actions.setFieldValueByKey(toggled, this.props.fieldKey);
    },
    render: function render() {
        var _React$createElement, _extends2, _extends3, _extends4, _extends5, _React$createElement2;

        if (typeof this.props.isActive == 'boolean' && !this.props.isActive) {
            return null;
        }

        var prepareStyles = this.context.muiTheme.prepareStyles;

        var styleObjectFromTheme = getStyles(this.props, this.context, this.state);

        var isInfoMode = this._isInfoMode();
        var infoModeStyles = isInfoMode ? {
            labelStyle: _extends({
                cursor: 'not-allowed'
            }, styleObjectFromTheme.label, this.props.labelStyle || {})
        } : {};

        var labelProps = _.pick(this.props, Object.keys(Label.propTypes));
        var errorTextElement = !_.isEmpty(this.props.richErrorMessages) ? _react2.default.createElement(RichErrorMessages, (_React$createElement = { messages: this.props.richErrorMessages, 'data-codecept-selector-node': 'RichErrorMessages',
            'data-codecept-selector-file': 'Toggle'
        }, _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Toggle'), _defineProperty(_React$createElement, 'data-codecept-selector-node', 'RichErrorMessages'), _defineProperty(_React$createElement, 'data-codecept-selector-file', 'Toggle'), _React$createElement)) : null;

        //# hacky but only support a fix set of possible classNames for now

        var targetClassname = _Toggle4.default[this.props.className];

        return _react2.default.createElement(
            'div',
            (_React$createElement2 = {
                'data-codecept-selector-node': 'div',
                'data-codecept-selector-file': 'Toggle'
            }, _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Toggle'), _defineProperty(_React$createElement2, 'data-codecept-selector-node', 'div'), _defineProperty(_React$createElement2, 'data-codecept-selector-file', 'Toggle'), _React$createElement2),
            this.props.componentType == 'Checkbox' ? _react2.default.createElement(_Checkbox2.default, _extends({
                id: this.props.id,
                className: targetClassname,
                label: _react2.default.createElement(Label, _extends({}, labelProps, (_extends2 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'Toggle'
                }, _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Toggle'), _defineProperty(_extends2, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends2, 'data-codecept-selector-file', 'Toggle'), _extends2))),
                onCheck: this._onValueChange,
                checked: this.props.value,
                labelPosition: 'right'
            }, infoModeStyles, _Toggle_style2.default.Checkbox, {
                disabled: this.props.locked || this.props.disabled || isInfoMode
            }, _.get(this, 'props.infoModeProps.default'), _.get(this, 'props.infoModeProps.Toggle'), (_extends3 = {
                'data-codecept-selector-node': 'Checkbox',
                'data-codecept-selector-file': 'Toggle'
            }, _defineProperty(_extends3, 'data-codecept-selector-node', 'Checkbox'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Toggle'), _defineProperty(_extends3, 'data-codecept-selector-node', 'Checkbox'), _defineProperty(_extends3, 'data-codecept-selector-file', 'Toggle'), _extends3))) : _react2.default.createElement(_Toggle2.default, _extends({
                id: this.props.id,
                label: _react2.default.createElement(Label, _extends({}, labelProps, (_extends4 = {
                    'data-codecept-selector-node': 'Label',
                    'data-codecept-selector-file': 'Toggle'
                }, _defineProperty(_extends4, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Toggle'), _defineProperty(_extends4, 'data-codecept-selector-node', 'Label'), _defineProperty(_extends4, 'data-codecept-selector-file', 'Toggle'), _extends4))),
                onToggle: this._onValueChange,
                toggled: this.props.value,
                labelPosition: 'right'
            }, infoModeStyles, _Toggle_style2.default.Toggle, {
                disabled: this.props.locked || this.props.disabled || isInfoMode
            }, _.get(this, 'props.infoModeProps.default'), _.get(this, 'props.infoModeProps.Toggle'), (_extends5 = {
                'data-codecept-selector-node': 'Toggle',
                'data-codecept-selector-file': 'Toggle'
            }, _defineProperty(_extends5, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Toggle'), _defineProperty(_extends5, 'data-codecept-selector-node', 'Toggle'), _defineProperty(_extends5, 'data-codecept-selector-file', 'Toggle'), _extends5))),
            errorTextElement
        );
    },

    _isInfoMode: function _isInfoMode() {
        var mode = this.props.mode;


        return mode === 'info';
    }
});

exports.default = (0, _addInfoModeProps2.default)(FieldToggle);