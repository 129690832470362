'use strict';

var _ = require('lodash');

function curriedSelfModule_forFormatRequest(selfModule) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        listerConfig = _ref.listerConfig,
        _ref$additionalQueryP = _ref.additionalQueryParams,
        additionalQueryParams = _ref$additionalQueryP === undefined ? {} : _ref$additionalQueryP;

    var _selfModule$getActive = selfModule.getActiveRubyClientInfo(),
        rubyClientId = _selfModule$getActive.client,
        subsiteId = _selfModule$getActive.subsite;

    return function (req, tableState, store) {
        var formattedQuery = selfModule.listerQueryFromGriddleState(store, { listerConfig: listerConfig, additionalQueryParams: additionalQueryParams });
        req = req.query(formattedQuery);
        return req;
    };
}

function formatSuccess(resp, tableState) {
    var total = resp.total;
    return {
        page: tableState.page,
        maxPage: parseInt(total / tableState.pageSize, 10) + (total % tableState.pageSize ? 1 : 0),
        totalCount: total,
        data: resp.data
    };
}

module.exports = {
    curriedSelfModule_forFormatRequest: curriedSelfModule_forFormatRequest,
    formatSuccess: formatSuccess
};