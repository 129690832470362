'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var request = require('@rubyapps/ruby-superagent');
var _ = require('lodash');

var url = require('url');
var urljoin = require('url-join');

var LIFTED_OPTIONS = ['id', 'templateKey', 'editorName', 'subsiteUrl', 'rubyClientUrl', 'title', 'showEditAction', 'pageName', 'selectedLanguage', 'delegateActions', 'additionalHeaderElements'];

var TYPES = {
    OPEN_CONTENT_COMPARE_MODAL: '@@ruby-app/content-compare/OPEN_CONTENT_COMPARE_MODAL',
    CLOSE_CONTENT_COMPARE_MODAL: '@@ruby-app/content-compare/CLOSE_CONTENT_COMPARE_MODAL',
    RESET_CONTENT_COMPARE_MODAL: '@@ruby-app/confirm-dialog/RESET_CONTENT_COMPARE_MODAL',
    SET_COMPARE_DATA: '@@ruby-app/content-compare/SET_COMPARE_DATA',
    SET_CONFIG: '@@ruby-app/content-compare/SET_CONFIG',
    SET_IS_LOADING: '@@ruby-app/content-compare/SET_IS_LOADING',
    CLEAR_IS_LOADING: '@@ruby-app/content-compare/CLEAR_IS_LOADING',
    CLEAR_URLS: '@@ruby-app/content-compare/CLEAR_URLS',
    SET_SELECTED_LANGUAGE: '@@ruby-app/content-compare/SET_SELECTED_LANGUAGE',
    SHOW_CONTENT_APPROVAL_ACTIONS: '@@ruby-app/content-compare/SHOW_CONTENT_APPROVAL_ACTIONS'
};

var generators = {
    /*
     * options = {
     *      formData:
     *      editUrl: <Object> || <string>
     *      version_data_id:
     *      id:
     *      templateKey:
     *      selectedLanguage:
     * }
     * */
    retrievePreviewData_thenOpen_usingOptions: function retrievePreviewData_thenOpen_usingOptions(options) {
        //# TODO
        var self = this;
        var actions = this.getAction().generators;
        var id = options.id,
            templateKey = options.templateKey,
            formData = options.formData,
            version_data_id = options.version_data_id,
            selectedLanguage = options.selectedLanguage;

        var _getDependencies = this.getDependencies(),
            frontendSettingsComponent = _getDependencies.frontendSettingsComponent;

        var frontendSettings = frontendSettingsComponent.getState();
        var restApiRoot = frontendSettings.restApiRoot;


        var previewURL = urljoin(restApiRoot, 'previews');
        var previewData = {
            entity_id: id ? id : 0,
            templateKey: templateKey
        };

        var pageName = void 0;
        if (formData) {
            previewData.raw_data = JSON.stringify(_.assign(id ? { id: id } : {}, formData));
            pageName = formData.name;
        } else if (version_data_id) {
            previewData.version_data_id = version_data_id;
        }

        return function (dispatch, getState) {
            var requestPromise = void 0;

            requestPromise = request.post(previewURL).send(previewData);

            dispatch(actions.setConfig(_extends({
                showContentApprovalActions: false,
                additionalHeaderElements: []
            }, _.pick(options, LIFTED_OPTIONS), {
                pageName: options.pageName || pageName,
                isComparingMode: false,
                options: options
            })));

            dispatch(actions.setIsLoading());

            dispatch(actions.open_contentCompare_modal());
            return requestPromise.then(function success(response) {

                var data = response.body;

                var preview_guid = data.preview_guid;
                var preview_version_data_id = data.version_rec.version_data_id;

                var previewPageURL = urljoin(window.location.protocol + '//' + window.location.host, '/content/secure-preview/page/', preview_guid) + '?version_data_id=' + preview_version_data_id + (selectedLanguage ? '&lang=' + selectedLanguage : '');

                dispatch(actions.clearIsLoading());
                dispatch({
                    type: TYPES.SET_COMPARE_DATA,
                    payload: {
                        urls: {
                            previewPage: previewPageURL
                        }
                    }
                });
            }, function error(err) {
                dispatch(actions.clearIsLoading());
                self.showErrorNotification({
                    error: err
                });
            });
        };
    }

    //# TODO: move to draft plugin via a mixin and register against this component (similar to edit page)
    , retrieveReviewData_thenOpen_usingOptions: function retrieveReviewData_thenOpen_usingOptions(options) {
        var selfState = this.getState();
        var self = this;
        var actions = this.getAction().generators;
        var compareURL = options.compareURL,
            compareURLData = options.compareURLData,
            labels = options.labels;


        var selectedLanguage = options.selectedLanguage ? options.selectedLanguage : selfState.selectedLanguage;

        var parentID = self.getID();
        var childNotification = self.findChildByID(parentID + '.ruby_notifications');
        var childNotificationActions = childNotification ? childNotification.getAction().generators : null;

        return function (dispatch, getState) {
            var requestPromise = void 0;
            var pageName = void 0;
            if (compareURLData) {
                //# 20170228 - probably deprecated. We're creating the version record with new formData before this gets called... actually this might get called for content review
                //requestPromise = request.post(`${compareURL}&lang=${selectedLanguage}`, compareURLData);
                requestPromise = request.post(compareURL).query({ lang: selectedLanguage }).send(compareURLData);

                pageName = _.get(compareURLData, 'updatedData.name');
            } else {
                //requestPromise = request.get(`${compareURL}&lang=${selectedLanguage}`);
                requestPromise = request.post(compareURL).query({ lang: selectedLanguage });
            }
            dispatch(actions.setConfig(_extends(_.pick(options, LIFTED_OPTIONS)
            //# TODO: remove delegateActions, editorName once we can get rid of 'delegateReject' etc
            , {
                pageName: options.pageName || pageName,
                isComparingMode: true,
                options: options
            })));
            dispatch(actions.setIsLoading());
            dispatch(actions.open_contentCompare_modal());
            return requestPromise.then(function success(response) {
                var urls = response.body.urls;
                var docs = response.body.docs;
                var message = response.body.message;

                dispatch(actions.clearIsLoading());
                dispatch({
                    type: TYPES.SET_COMPARE_DATA,
                    payload: { urls: urls, docs: docs, labels: labels }
                });

                if (childNotificationActions && message) {
                    dispatch(childNotificationActions.showNotification(message));
                }

                //# TODO: remove once we dont need the legacy content approval plugin (or we've migrated the functionality)
                if (options.showContentApprovalActions) {
                    dispatch({
                        type: TYPES.SHOW_CONTENT_APPROVAL_ACTIONS
                    });
                }

                self.getState();
            }, function error(err) {
                dispatch(actions.clearIsLoading());
                self.showErrorNotification({
                    error: err
                });
            });
        };
    }

    //# usually called only because of a language change
    , refreshReviewData_forSelectedLanguage: function refreshReviewData_forSelectedLanguage(selectedLanguage) {
        var selfState = this.getState();
        var self = this;
        var actions = this.getAction().generators;
        var _selfState$options = selfState.options,
            compareURL = _selfState$options.compareURL,
            compareURLData = _selfState$options.compareURLData,
            labels = _selfState$options.labels;


        return function (dispatch, getState) {
            dispatch(actions.setIsLoading());

            var requestPromise = void 0;
            var pageName = void 0;
            if (compareURLData) {
                //requestPromise = request.post(`${compareURL}&lang=${selectedLanguage}`, compareURLData);
                requestPromise = request.post(compareURL).query({ lang: selectedLanguage }).send(compareURLData);

                pageName = _.get(compareURLData, 'updatedData.name');
            } else {
                //requestPromise = request.get(`${compareURL}&lang=${selectedLanguage}`);
                requestPromise = request.post(compareURL).query({ lang: selectedLanguage });
            }

            return requestPromise.then(function success(response) {
                var urls = response.body.urls;
                var docs = response.body.docs;
                var message = response.body.message;

                dispatch(actions.clearIsLoading());
                dispatch({
                    type: TYPES.SET_COMPARE_DATA,
                    payload: { urls: urls, docs: docs, labels: labels }
                });
            }, function error(err) {
                dispatch(actions.clearIsLoading());
                self.showErrorNotification({
                    error: err
                });
            });
        };
    }

    /*
     * options = {
     *      formData:
     *      versionDataId:
     *      id:
     *      templateKey:
     *      baseEndpoint: //# the api endpoint for the item
     *      selectedLanguage:
     * }
     * */
    //# creates a version data id for the raw data
    , reviewFormDataWithVersionDataId_usingOptions: function reviewFormDataWithVersionDataId_usingOptions(options) {

        var self = this;
        var actions = this.getAction().generators;
        var id = options.id,
            baseEndpoint = options.baseEndpoint,
            formData = options.formData,
            selectedLanguage = options.selectedLanguage,
            templateKey = options.templateKey,
            versionDataId = options.versionDataId;

        var _getDependencies2 = this.getDependencies(),
            frontendSettingsComponent = _getDependencies2.frontendSettingsComponent;

        var frontendSettings = frontendSettingsComponent.getState();
        var restApiRoot = frontendSettings.restApiRoot;


        var previewURL = urljoin(restApiRoot, 'previews');
        var previewData = {
            entity_id: id ? id : 0,
            templateKey: templateKey,
            raw_data: JSON.stringify(_.assign(id ? { id: id } : {}, formData))
        };

        if (!versionDataId) {
            // creating a page
            return actions.retrievePreviewData_thenOpen_usingOptions(options);
        }

        return function (dispatch, getState) {
            var pageName = formData.name;

            return request.post(previewURL).send(previewData).then(function success(response) {

                var data = response.body;

                var preview_guid = data.preview_guid;
                var preview_version_data_id = data.version_rec.version_data_id;

                var parsedBaseEndpoint = url.parse(baseEndpoint);

                var compareURL = urljoin(parsedBaseEndpoint.pathname, '/compare-versions', '?versionDataId=' + preview_version_data_id + '&basisVersionDataId=' + versionDataId);

                dispatch(actions.retrieveReviewData_thenOpen_usingOptions({
                    compareURL: compareURL,
                    formData: formData //# include formData so we can access it in the connector
                    //# this is used to determine which languages are active
                    , id: id,
                    pageName: pageName,
                    selectedLanguage: selectedLanguage,
                    templateKey: templateKey
                }));
            }, function error(err) {
                self.showErrorNotification({
                    error: err
                });
            });
        };
    },
    setConfig: function setConfig(options) {
        return {
            type: TYPES.SET_CONFIG,
            payload: options
        };
    },
    setIsLoading: function setIsLoading() {
        return {
            type: TYPES.SET_IS_LOADING
        };
    },
    clearIsLoading: function clearIsLoading() {
        return {
            type: TYPES.CLEAR_IS_LOADING
        };
    },

    _setSelectedLanguage: function _setSelectedLanguage(selectedLanguage) {
        return {
            type: TYPES.SET_SELECTED_LANGUAGE,
            payload: {
                selectedLanguage: selectedLanguage
            }
        };
    },
    setSelectedLanguage: function setSelectedLanguage(selectedLanguage) {
        var actions = this.getAction().generators;
        var selfState = this.getState();

        return function (dispatch) {
            dispatch(actions._setSelectedLanguage(selectedLanguage));

            if (selfState.isComparingMode) {
                dispatch(actions.refreshReviewData_forSelectedLanguage(selectedLanguage));
            }
        };
    },
    open_contentCompare_modal: function open_contentCompare_modal() {
        return {
            type: TYPES.OPEN_CONTENT_COMPARE_MODAL
        };
    },
    close_contentCompare_modal: function close_contentCompare_modal() {
        return {
            type: TYPES.CLOSE_CONTENT_COMPARE_MODAL
        };
    },
    reset: function reset() {
        return {
            type: TYPES.RESET_CONTENT_COMPARE_MODAL
        };
    },
    closeAndReset: function closeAndReset() {
        var actions = this.getAction().generators;
        return function (dispatch) {
            dispatch(actions.close_contentCompare_modal());
            setTimeout(function () {
                dispatch(actions.reset());
            }, 500);
        };
    },
    clearUrls: function clearUrls() {
        return {
            type: TYPES.CLEAR_URLS
        };
    }
};

module.exports = {
    TYPES: TYPES,
    generators: generators
};