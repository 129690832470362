'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generator;

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _redux = require('redux');

var _reactRedux = require('react-redux');

var _Text = require('./Text');

var _Text2 = _interopRequireDefault(_Text);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function generator() {
    var selfModule = this;

    var _selfModule$getDepend = selfModule.getDependencies(),
        selfSelector = _selfModule$getDepend.selfSelector,
        currentUserSelector = _selfModule$getDepend.currentUserSelector;

    //# figure out the toHTML ahead of time


    var hydratedToHTML = this.hydratedToHTMLFromProps(this.props);

    function mapStateToProps(state, ownProps) {
        var selfState = selfSelector(state);

        var mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);

        var formattedError = selfModule.formattedErrorFromState(state);

        var fieldKey = mixinFieldProps.fieldKey;


        var props = selfModule.getProps(true);
        var mappedProps = _extends({}, ownProps, props, mixinFieldProps, _lodash2.default.pick(selfState.fields[fieldKey], ['value']), formattedError, hydratedToHTML ? { toHTML: hydratedToHTML } : {});

        return mappedProps;
    }

    function mapDispatchToProps(dispatch, ownProps) {
        return {
            actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
        };
    }

    return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Text2.default);
}