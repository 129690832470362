'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _ = require('lodash');
var path = require('path');
var rubyLogger = require('@rubyapps/ruby-logger');
var packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
var logger = rubyLogger.getLogger(packageName);

module.exports = {
    getProps: function getProps(shouldHydrateProps) {
        var mergedProps = _extends({}, this.props, this.getState().props);
        var finalProps = mergedProps;

        if (shouldHydrateProps) {
            logger.warn('shouldHydrateProps is not supported in this mixin');
        }

        return finalProps;
    },
    action: function action() {
        var TYPES = function (id) {
            return {
                SET_PROPS: '@@ruby-app/' + id + '/SET_PROPS'
            };
        }(this.getID());

        return {
            TYPES: TYPES,
            generators: {
                setProps: function setProps(props) {
                    var TYPES = this.getAction().TYPES;
                    return {
                        type: TYPES.SET_PROPS,
                        payload: {
                            props: props
                        }
                    };
                }
            }
        };
    },
    reducer: function reducer() {
        var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { props: {} };
        var action = arguments[1];

        var _getAction = this.getAction(),
            TYPES = _getAction.TYPES;

        var type = action.type,
            payload = action.payload;


        switch (type) {
            case TYPES.SET_PROPS:
                return _extends({}, state, {
                    props: _extends({}, state.props, payload.props)
                });
            default:
                return state;
        }
    },
    getFromListerContext: function getFromListerContext() {
        //# TODO: support overriding context?
        //# we should use the same context used for the lister pages 

        if (!this.getState().routeParams.routeActive) {
            console.warn('getFromListerContext() was called for ' + this.getID() + ' when it is not active');
        }

        return this.props.fromListerContext || window.location.pathname;
    },
    listerQueryFromGriddleState: function listerQueryFromGriddleState(griddleState) {
        var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
            explicitListerConfig = _ref.listerConfig,
            _ref$additionalQueryP = _ref.additionalQueryParams,
            additionalQueryParams = _ref$additionalQueryP === undefined ? {} : _ref$additionalQueryP;

        var selfModule = this;

        var _getDependencies = this.getDependencies(),
            rubyClientFKComponent = _getDependencies.rubyClientFKComponent,
            listerConfigsComponent = _getDependencies.listerConfigsComponent;

        var _selfModule$getActive = selfModule.getActiveRubyClientInfo(),
            rubyClientId = _selfModule$getActive.client,
            subsiteId = _selfModule$getActive.subsite;

        var _ref2 = selfModule.getProps ? selfModule.getProps() : selfModule.props,
            staticFilterTags = _ref2.staticFilterTags,
            internalFilterTags = _ref2.internalFilterTags,
            _ref2$internalFieldsQ = _ref2.internalFieldsQuery,
            internalFieldsQuery = _ref2$internalFieldsQ === undefined ? {
            fields: [],
            expand: []
        } : _ref2$internalFieldsQ,
            props__listerConfig = _ref2.listerConfig,
            _ref2$templateKey = _ref2.templateKey,
            templateKey = _ref2$templateKey === undefined ? selfModule.getState().routeParams.template : _ref2$templateKey;

        var defaultListerConfig = listerConfigsComponent.listerConfigForKey(templateKey, selfModule);

        var listerConfig = listerConfig || defaultListerConfig || props__listerConfig;

        var _griddleState$pagePro = griddleState.pageProperties,
            page = _griddleState$pagePro.currentPage,
            pageSize = _griddleState$pagePro.pageSize,
            sortColumnsFromTableState = _griddleState$pagePro.sortColumns,
            sortAscending = _griddleState$pagePro.sortAscending,
            sortDescending = _griddleState$pagePro.sortDescending,
            filter = griddleState.filter;


        var filterAsObject = _.isString(filter) ? { filterTags: [], queryString: filter } : _extends({}, filter);

        //# include staticFilterTags and internalFilterTags in an adjacent where filter
        var filterAsObjectArray = [filterAsObject, internalFilterTags && internalFilterTags.length ? { filterTags: internalFilterTags } : undefined, staticFilterTags && staticFilterTags.length ? { filterTags: staticFilterTags } : undefined].filter(function (n) {
            return n;
        });

        var sortDirectionFromTableState = sortAscending ? 'asc' : 'desc';

        var sortDict = selfModule.utils.getSnameAndSdirAsDictFromSortColumns_andSortDir_usingGriddleStore(sortColumnsFromTableState, sortDirectionFromTableState, griddleState);

        var columns = selfModule.utils.getColumnsFromGriddleState_defaultingToColumns(griddleState, listerConfig.columns);
        var fieldsQueryObject = selfModule.utils.formatRequestPropsForColumns(columns);

        var allWhereFilters = filterAsObjectArray.map(function (filterObject) {
            return selfModule.utils.savedSearchToWhereFilter(filterObject, templateKey);
        }).filter(function (n) {
            return n && _.values(n).filter(function (o) {
                return o !== undefined;
            }).length;
        })
        //# NOTE: {key: undefined} gets translated as {} when calling on JSON.stringify
        //# We could also be explicit and perform a _.mapValue() and translate undefined to neq: [] or neq: null
        //# NOTE that we need to allow null's through though
        ;

        //# == Handling Custom Filters ===========================================//
        //# NOTE: for now we'll do this
        //# but eventually we want to fold this into the 
        //# filter.where query param
        var utilityBarState = griddleState.utilityBarProperties || {};
        var customFilters = _.get(listerConfig, 'filters', []);
        var customFiltersState = _.get(utilityBarState, 'customFilters');
        var defaultQuery = _.get(selfModule, 'props.listerConfig.defaultQuery') || {};
        var filterQuery = _.reduce(customFilters, function (result, filterSpec, idx) {
            var _ref4;

            var filterName = filterSpec.name || filterSpec.key;
            var filterKey = filterSpec.key || filterSpec.name;
            var filterValue = _.get(customFiltersState, [filterKey] //[filterName]
            , filterSpec.defaultValue);
            return filterValue ? _extends({}, result, filterSpec.isPlainArg ? _defineProperty({}, filterKey, filterValue) : (_ref4 = {}, _defineProperty(_ref4, 'filterType' + idx, filterKey), _defineProperty(_ref4, 'filterVal' + idx, filterValue), _ref4)) : result;
        }, {});

        var whereFilter = allWhereFilters.length > 1 ? {
            and: allWhereFilters
        } : allWhereFilters[0];

        //# NOTE: if we need to, we'll add a query modifier middleware to allow listers to modify the resultant queries
        var baseQuery = _extends({
            p: page - 1,
            psize: pageSize,
            search: filterAsObject.queryString,
            fields: _.uniq(fieldsQueryObject.fields.concat(internalFieldsQuery.fields))
            //# NOTE: fields can be keypaths, the server only uses the root key when deferring to loopback to retrieve specific fields
            , expand: _.uniq((fieldsQueryObject.expand || []).concat(internalFieldsQuery.expand || []))
            //# TODO: expand should contain the dataPath because the server needs the keypath to the profile selector,
            //# and the hydfration needs the last key in the path
            , sname: sortDict.sname,
            sdir: sortDict.sdir,
            ruby_client_fk: rubyClientId,
            content_subsite_fk: subsiteId,
            'filter.where': whereFilter ? JSON.stringify(whereFilter) : undefined
        }, additionalQueryParams);

        return _extends({}, baseQuery, defaultQuery, filterQuery);
    }
};