'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reduxBatchedActions = require('redux-batched-actions');

var _index = require('@rubyapps/ruby-component-builder/src/common/index');

var _index2 = _interopRequireDefault(_index);

var _RubyComponentFieldDynamicFormConnector = require('./reactComponents/RubyComponentFieldDynamicFormConnector');

var _RubyComponentFieldDynamicFormConnector2 = _interopRequireDefault(_RubyComponentFieldDynamicFormConnector);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var Promise = require('bluebird');
var IS_DEV_MODE = "production" !== 'production';

var React = require('react');
var _ = require('lodash');


var RubyComponent = require('@rubyapps/ruby-component');
var PropTypes = RubyComponent.PropTypes;

var CONSTANTS = require('../common/constants');
var commonUtils = require('../common/utils');

var _require = require('./utils'),
    getUniqueTopLevelKeysFromDynamicFormOptions = _require.getUniqueTopLevelKeysFromDynamicFormOptions;

var componentName = CONSTANTS.COMPONENT_NAME;

//# NOTE: helps with hydrating dependencies if you need the RubyComponent instances given an id
//import { hydrateDependenciesForRubyComponent } from '@rubyapps/ruby-component/src/client/utils/index';

//# mixins
var baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
var fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
var fieldPropsMixin = require('@rubyapps/ruby-component-mixin-field-props');
var guidHelperMixin = require('@rubyapps/ruby-component-mixin-field-guid-helper');
var fieldDynamicMixin = require('@rubyapps/ruby-component-mixin-field-dynamic');
var diffHelperMixin = require('@rubyapps/ruby-component-mixin-field-diff-helper');
var reduxHelperMixin = require('@rubyapps/ruby-component-mixin-redux-helper');
var fieldSelflessMixin = require('@rubyapps/ruby-component-mixin-field-selfless');

var action = require('./action');
var reducer = require('./reducer');
var displayValue = require('./displayValue');

var _require2 = require('@rubyapps/advanced-loopback-filters'),
    createLoopbackFilterer = _require2.createLoopbackFilterer,
    mapKeypathsInQuery = _require2.mapKeypathsInQuery;

//# do not retrieve children form values which have not been user modified
//# this is because the value is most likely a default value, which we should ignore for the purposes of
//# updating children


function omitFieldPicker(node) {
    var nodeState = node.getState();
    var nodeKey = node.props.key;

    //# need to support recursively omitting
    //# so we don't want to omit prematurely
    if (!nodeKey) {
        return false;
    }

    var fieldState = _.get(nodeState, ['fields', nodeKey], {});

    //# TODO: better handling for complex components such as NamespaceSelector since the 'self' component wouldn't be user modified (in some cases)
    //# It is currently ok as long as components such as NamespaceSelector *isn't* a child of a DynamicForm.option
    //# Otherwise, it'll affect rehydrating the children form values
    return fieldState.userModifiedTimestamp == null;
}

var RCRubyComponentFieldDynamicForm = RubyComponent.createClass(_extends({
    mixins: [baseFieldMixin, fieldValidationMixin, fieldPropsMixin, guidHelperMixin, fieldDynamicMixin, diffHelperMixin, reduxHelperMixin],
    propTypes: {
        // dependentComponentID: PropTypes.string
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.array,
            query: PropTypes.object
        })),
        shouldPassthroughProps: PropTypes.bool //# DynamicForm may be used in a situation where the parent passes down props
        //# by default this should not be required since we expect each of these components to be self-contained
        //# however, components like ContextMenu relies on cloning the children to pass down onTouchTap
        //# so we need to make sure we pass that along 
    },
    staticPropsByComponent: {
        'ruby-component-field-editor': {
            fieldInfo: {
                displayText: 'Dynamic Form',
                propertyKeys: ['object_options_hidden', 'help_text', 'permissions', 'namespace']
            }
        }
    },
    componentName: componentName,
    action: action,
    reducer: reducer
    //, getDefaultProps: () => ({})
    //# getInitialState: () => ({}) //# initial redux state
    /*
    , dependencies: function() {
        //const root = this.getRoot();
        //const dependentComponent = root.findDescendentByID(this.props.dependentComponentID);
         const hydratedDependencies = hydrateDependenciesForRubyComponent(this, {
            dependentComponent: this.props.dependentComponentID
        });
         return {
            //, dependentComponent
            ...hydratedDependencies
        };
    }
    */
    , getReactClass: function getReactClass() {
        return _RubyComponentFieldDynamicFormConnector2.default.apply(this);
    },
    getReactElement: function getReactElement() {
        var _extends2;

        var RubyComponentFieldDynamicFormComponent = this.getReactClass();

        return React.createElement(RubyComponentFieldDynamicFormComponent, _extends({}, this.props, (_extends2 = {
            'data-codecept-selector-node': 'RubyComponentFieldDynamicFormComponent',
            'data-codecept-selector-file': 'index'
        }, _defineProperty(_extends2, 'data-codecept-selector-node', 'RubyComponentFieldDynamicFormComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _defineProperty(_extends2, 'data-codecept-selector-node', 'RubyComponentFieldDynamicFormComponent'), _defineProperty(_extends2, 'data-codecept-selector-file', 'index'), _extends2)));
    }

    //== DEFINITIONS =============================================//
    /*
    _watchingKeypathSpecsByKeypath: {
        '/json/pointer': {
            level: -1|#, //if -1, it's the root formValue, otherwise, we traverse up that many times
            , formattedKeypath: 'json.pointer'
        }
        , '1/json/pointer': {
            level: 1,
            , formattedKeypath: '1.json.pointer'
        }
        , 'key.path.to.value': {
            level: -1,
            , formattedKeypath: 'key.path.to.value'
        }
    }
    */
    //== OVERRIDES ===============================================//

    , updateChildren: function updateChildren(shouldRequestRerender, dispatchOrCollect) {
        var _this = this;

        var replacedChildren = this.replaceChildren();

        if (!replacedChildren) {
            return;
        }

        //# NOTE: we could diff the replacedChildren with this.getChildren() to have a better idea of what children we
        //# should seed console.log('[updateChildren] children was replaced. checking if we need to reseed');

        //# get children formValue
        var fieldValue = this.getFieldValue();
        var shouldCallOnDispatch = fieldValue || shouldRequestRerender;

        if (!shouldCallOnDispatch) {
            return;
        }

        var shouldTriggerDispatch = false;
        var arrayOfActions = [];
        if (!dispatchOrCollect) {
            shouldTriggerDispatch = true;

            dispatchOrCollect = function dispatchOrCollect(action) {
                arrayOfActions.push(action);
            };
        }

        var formValueToChildrenPromise = void 0;

        var childrenFormValue = this.childrenFormValue({ excludeNull: true, omitFieldPicker: omitFieldPicker });
        if (fieldValue) {
            //# prefer childrenFormValue, since those would have changed
            var mergedFormValue = _extends({}, fieldValue, childrenFormValue);
            /*
            console.log(
                '[updateChildren] fieldValue Exists. Attempting to seed new children. mergedFormValue:'
                , mergedFormValue
                , this.getID()
            );
            */

            var childrenHasState = _.every(this.getChildren().map(function (n) {
                return n.getState();
            }));

            //console.log(`[DEBUG][${this.getID()}] this.getState()`, this.getState(), mergedFormValue, `fieldValue`,fieldValue, 'childrenHasState', childrenHasState);

            //# in the case of a DynamicForm within a DynamicForm, the inner DynamicForm's children state might have been cleared due to a bunch of rerendering 
            //# (the Parent DynamicForm reacting to form changes)
            //# this would have resulted in multiple updates, one of which might not have children who have their localstate hydrated 
            //# because it's right before another updateChildren request
            //# so we should check and skip, relying on the latter update request to eventually populate the children form value
            if (childrenHasState) {
                formValueToChildrenPromise = this._formValueToChildrenLocalState(mergedFormValue, dispatchOrCollect);
            }
        }
        if (shouldRequestRerender) {
            var _getAction = this.getAction(),
                generators = _getAction.generators;

            dispatchOrCollect(generators.requestRerender());
        }

        if (shouldTriggerDispatch) {
            return (formValueToChildrenPromise || Promise.resolve()).then(function () {
                _this.getStore().dispatch((0, _reduxBatchedActions.batchActions)(arrayOfActions));
            });
        }

        return formValueToChildrenPromise || Promise.resolve();
    },
    children: function children() {
        var _this2 = this;

        var options_meth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { forceNew: false };

        if (this.getStore() == undefined || !this.getState()) {
            //# wait until store is ready and call on updateChildren

            setTimeout(function () {
                _this2.queueUpdateChildren(true);
            }, 250);

            return [];
        }

        var existingChildren = this._children;
        if (options_meth.forceNew == false && existingChildren && existingChildren.length) {
            //# We do not want to create new children if it already exists
            return existingChildren;
        }

        var selfID = this.getID();
        var selfState = this.getState();

        var options = _.get(selfState, 'props.options') || this.props.options || [];

        //# register all keypaths
        this.registerKeypathsInOptions(_.get(this.getState(), 'props.options') || this.props.options || []);

        if (this._cachedChildrenByUUID == undefined) {
            this._cachedChildrenByUUID = {};
        }
        //# UUID is the index of the options for now since the order never changes
        var cachedChildrenByUUID = this._cachedChildrenByUUID;
        var childrenComponentUUIDs = [];

        var hashedOptions = this.hashedItems(this.filteredOptionsByTheirQueries(options));

        //# need to include any props.__formattedChildren
        var explicitChildren = fieldDynamicMixin.children.apply(this, arguments);

        var childrenComponents = hashedOptions.reduce(function (collector, hashedOption) {
            //# TODO: we probably want to give each option a UUID
            //# cause the list of options might change
            //# although the order won't change so it's not like the option at a certain index would change dynamically
            var childID = hashedOption._hash,
                option = hashedOption._value;


            var cachedChild = cachedChildrenByUUID[childID];
            childrenComponentUUIDs.push(childID);
            if (cachedChild) {
                return collector.concat(cachedChild);
            }

            var idPrefix = selfID + '[' + childID + ']';
            var builtChildComponents = _this2.buildChildComponentsForOption(option, idPrefix);

            cachedChildrenByUUID[childID] = builtChildComponents;

            return collector.concat(builtChildComponents);
        }, []).filter(function (child) {
            return child != undefined;
        }).concat(explicitChildren);

        //# purge old cache
        this._cachedChildrenByUUID = _.pick(cachedChildrenByUUID, childrenComponentUUIDs);

        return [childrenComponents];
    },

    _formValueToLocalState: function _formValueToLocalState(formValue, dispatchOrCollect, isError, entireFormValue) {
        var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        this.cachePromisedOnceResolved(options.promisedOnceResolved);

        var selfKey = this.props.key;

        var formValueForSelf = selfKey == undefined && !formValue.hasOwnProperty(selfKey) ? _defineProperty({}, selfKey, formValue)
        //# need to account for the case where selfKey is undefined and the formValue given isn't normalized to
        //# be {undefined: ...}
        : formValue;

        var formValueForChildren = formValue.hasOwnProperty(selfKey) ? formValue[selfKey] : formValue;

        //# replaceChildren based on parentFormComponent's pristineFormData. `formValueFromLocalState` isn't ready
        this.replaceChildren();
        return Promise.all([
        //# we're calling baseFieldMixin with an explicit key
        //# (either 'undefined' or whatever this.props.key is,
        //# in order to make sure the entirety of the formValue is cached
        //# so that when the dynamicForm rerenders, we have the formValue to
        //# reseed the updated children
        baseFieldMixin._formValueToLocalState.call(this, formValueForSelf, dispatchOrCollect, isError, entireFormValue, _extends({ ignoreChildren: true }, options))
        //# NOTE: we need to spread the result out
        , this._formValueToChildrenLocalState(formValueForChildren, dispatchOrCollect, isError, entireFormValue, options)]);
    }

    //# since we store the full formValue for DynamicForm locally in order to help rerendering
    //# we don't want to return that value if there's no key
    , _formValueFromLocalState: function _formValueFromLocalState() /*selfState, isError, predicateFormatter, entireState, options={}*/{
        if (this.props.key) {
            return baseFieldMixin._formValueFromLocalState.apply(this, arguments);
        } else {
            return fieldSelflessMixin._formValueFromLocalState.apply(this, arguments);
        }
    },

    onReduxInit: function onReduxInit() {
        var _this3 = this;

        var store = this.getStore();

        this.updateChildrenIfFormValueIsDifferent();
        var unsub = store.subscribe(function () {
            _this3.updateChildrenIfFormValueIsDifferent();
        });

        return unsub;
    },

    onReduxTearDown: function onReduxTearDown() {
        //# unwatch the keypaths we were watching
        this.clearWatchingKeypaths();
    }

    //== UTILITIES ==========================================//

    /**
     *  Debounced check for formValue changes. We don't want to overly updateChidren
     */
    , updateChildrenIfFormValueIsDifferent: function updateChildrenIfFormValueIsDifferent() {
        var _this4 = this;

        var updateChildrenIfFormValueIsDifferent_debounced = this._updateChildrenIfFormValueIsDifferent_debounced || _.debounce(function () {

            var previousFlattenedFormValueBeingWatched = _this4._cachedFlattened_formValueBeingWatched;

            var formValueBeingWatched = _this4.formValueBeingWatched();

            var nextFlattenedFormValueBeingWatched = formValueBeingWatched ? _this4.flattenedObject(formValueBeingWatched) : undefined;

            var formValueIsDifferent = !_.isEqual(previousFlattenedFormValueBeingWatched, nextFlattenedFormValueBeingWatched);

            _this4._cachedFlattened_formValueBeingWatched = nextFlattenedFormValueBeingWatched;
            _this4._cached_formValueBeingWatched = formValueBeingWatched;

            if (formValueIsDifferent) {
                //console.log(`==== DynamicForm formValueIsDifferent for: [${this.getID()}]`
                //    , previousFlattenedFormValueBeingWatched
                //    , nextFlattenedFormValueBeingWatched
                //);

                var newPendingUpdateChildren = _this4.promisedOnceResolve().then(function () {
                    return (
                        //# exising children updates should finish before
                        _this4.queueUpdateChildren(true)
                    );
                });
            }
        }, 300);

        if (!this._updateChildrenIfFormValueIsDifferent_debounced) {
            this._updateChildrenIfFormValueIsDifferent_debounced = updateChildrenIfFormValueIsDifferent_debounced;
        }

        updateChildrenIfFormValueIsDifferent_debounced();
    }

    //# TODO: IDEA: we need to handle batch children update
    //# because right now all update children calls won't batch
    , queueUpdateChildren: function queueUpdateChildren() {
        var _this5 = this,
            _arguments = arguments;

        var pendingUpdateChildren = this.getStatefulCacheForKey('pendingUpdateChildren') || Promise.resolve();

        var newPendingUpdateChildren = pendingUpdateChildren.then(function () {
            return _this5.updateChildren.apply(_this5, _arguments);
        }).then(function () {
            _this5.clearStatefulCacheForKey('pendingUpdateChildren');
        });

        this.setStatefulCacheForKey('pendingUpdateChildren', newPendingUpdateChildren);

        return newPendingUpdateChildren;
    },
    registerKeypathToWatch: function registerKeypathToWatch(keypathToWatch) {
        var watchingKeypaths = this._watchingKeypathSpecsByKeypath || {};

        if (watchingKeypaths.hasOwnProperty(keypathToWatch)) {
            return false;
        }

        var isJsonPointer = keypathToWatch.indexOf('/') >= 0;
        if (!isJsonPointer) {
            watchingKeypaths[keypathToWatch] = {
                isJsonPointer: false,
                level: -1 //# where -1 represents root
                , formattedKeypath: keypathToWatch
            };
        } else {
            var jsonPointerArr = keypathToWatch.split('/');
            if (jsonPointerArr[0] == '') {
                //# is absolute path
                watchingKeypaths[keypathToWatch] = {
                    isJsonPointer: true,
                    level: -1 //# where -1 represents root
                    , formattedKeypath: jsonPointerArr.slice(1).join('.')
                };
            } else {
                //# relative path
                watchingKeypaths[keypathToWatch] = {
                    isJsonPointer: true,
                    level: Number(jsonPointerArr[0]),
                    formattedKeypath: jsonPointerArr.join('.')
                };
            }
        }

        this._watchingKeypathSpecsByKeypath = watchingKeypaths;

        return true;
    },
    registerKeypathsInOptions: function registerKeypathsInOptions(options) {
        var _this6 = this;

        options.forEach(function (option) {
            mapKeypathsInQuery(option.query, function (keypath) {
                _this6.registerKeypathToWatch(keypath);
            });
        });
    },
    clearWatchingKeypaths: function clearWatchingKeypaths() {
        this._watchingKeypathSpecsByKeypath = undefined;
    },
    formattedKeypathForKeypath: function formattedKeypathForKeypath(keypath) {
        return _.get(this._watchingKeypathSpecsByKeypath, [keypath, 'formattedKeypath']);
    },

    formValueBeingWatched: function formValueBeingWatched(formValue) {
        //# register all keypaths
        this.registerKeypathsInOptions(_.get(this.getState(), 'props.options') || this.props.options || []);
        //# reregister because (I think) for DynamicForm within a DynamicForm,
        //# there might be an unmount event (when the top-level DynamicForm 
        //# needs to rerender the children DynamicForm, the child DF gets cleared
        //# with the watching keypaths

        if (!formValue) {
            formValue = this.formValueForFiltering();
        }

        if (!this._watchingKeypathSpecsByKeypath) {
            return undefined;
        }

        var keypaths = _.map(this._watchingKeypathSpecsByKeypath, function (watchingKeypathSpec) {
            return watchingKeypathSpec.formattedKeypath;
        });
        return _.pick(formValue, keypaths);
    },

    buildChildComponentsForOption: function buildChildComponentsForOption(option, idPrefix) {
        var _this7 = this;

        var optionValues = _.castArray(option.value);

        var builtChildComponents = optionValues.map(function (optionValue) {
            return (0, _index2.default)(optionValue, idPrefix, undefined, undefined, { _parent: _this7 });
        });

        return builtChildComponents;
    },
    childrenFormValue: function childrenFormValue() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { excludeNull: true /*, omitFieldPicker*/ };

        //# NOTE: do not exclude non-user modified fields by default
        //# because it's used by the hasUnsavedChanges() check
        //# we do use it for 'updateChildren' calls
        var children = this.getChildren() || [];

        return children.length ? Object.assign.apply({}, children.map(function (child) {
            return child.formValue(options) || {};
        })) : {};
    },
    formValueForFiltering: function formValueForFiltering() {
        return this.formValueForFilteringFromLocalState(this.getState(), this.getStore().getState());
        /*
        return _.reduce(
            (this._watchingKeypathSpecsByKeypath || {})
            , (collector, watchingKeypathSpec) => {
                const {
                    level
                } = watchingKeypathSpec;
                 if (level > -1 && !collector.hasOwnProperty(level)) {
                    const targetAncestorNode = Array.apply(null, {length: level}).reduce(currentNode => {
                        return currentNode.getParent().findAncestorBy(node => !_.isNil(node.props.key));
                    }, this.findAncestorBy(node => !_.isNil(node.props.key)));
                     const targetAncestorNode__childrenFormValue = targetAncestorNode.childrenFormValue();
                    //# only retrieve pending formValue if the JSON Pointer is relative
                     collector[level] = targetAncestorNode__childrenFormValue;
                }
                 return collector;
            }
            , this.getParentFormComponent().formValue());
        */
    },
    formValueForFilteringFromLocalState: function formValueForFilteringFromLocalState(_selfState, entireState) {
        var _this8 = this;

        //# returns the root formValue with additional keys
        //# {1: ..., 2: ...} where 1 is from '1/json/pointer'
        //# so that when we replace the / in the json pointer, it can be treated like a normal keypath
        var parentFormComponent = this.getParentFormComponent();
        var parentForm_localState = _.get(entireState, parentFormComponent.getKeypathArr(), {});

        //console.time('formValueForFilteringFromLocalState');
        var parentFormValue__all = parentFormComponent.formValueFromLocalState(parentForm_localState, undefined, entireState, { returnRaw: true });
        var parentForm__pristineFormData = parentForm_localState.pristineFormData;
        var parentFormValue__userEditedOnly = parentFormComponent.formValueFromLocalState(parentForm_localState, undefined, entireState, { returnRaw: true, omitFieldPicker: omitFieldPicker });
        //console.timeEnd('formValueForFilteringFromLocalState');

        var parentFormValue = _extends({}, parentFormValue__all, parentForm__pristineFormData, parentFormValue__userEditedOnly);
        //# NOTE: not the best implementation but we need to retrieve 3 flavors of the parentFormValue
        //# we need to include the pristineFormData to prevent a stalemate when resolving formValueToLocalState
        //# because the DynamicForm.formValueToChildrenLocalState() waits for children to be available
        //# TODO: consider doing a deep merge

        return _.reduce(this._watchingKeypathSpecsByKeypath || {}, function (collector, watchingKeypathSpec) {
            var level = watchingKeypathSpec.level;


            if (level > -1 && !collector.hasOwnProperty(level)) {
                var targetAncestorNode = Array.apply(null, { length: level }).reduce(function (currentNode) {
                    return currentNode.getParent().findAncestorBy(function (node) {
                        return !_.isNil(node.props.key);
                    });
                }, _this8.findAncestorBy(function (node) {
                    return !_.isNil(node.props.key);
                }));

                var targetAncestorNode_localState = _.get(entireState, targetAncestorNode.getKeypathArr());
                var targetAncestorNode__childrenFormValue = targetAncestorNode.childrenFormValueFromLocalState(targetAncestorNode_localState);
                //# only retrieve pending formValue if the JSON Pointer is relative

                collector[level] = targetAncestorNode__childrenFormValue;
            }

            return collector;
        }, parentFormValue);
    },

    filteredOptionsByTheirQueries: function filteredOptionsByTheirQueries(options, formValueForFiltering) {
        var _this9 = this;

        var loopbackFilterer = this._loopbackFilterer || createLoopbackFilterer({
            getValue: this.filterer_getValue.bind(this),
            selectFields: this.filterer_selectFields.bind(this)
        });

        this._loopbackFilterer = loopbackFilterer;

        if (!formValueForFiltering) {
            formValueForFiltering = this.formValueForFiltering();
        }

        var filteredOptions = options.filter(function (option) {
            var option__query = option.query;
            if (_.isFunction(option__query)) {
                return option__query.call(_this9, _this9);
            }

            var filteredFormValue = loopbackFilterer([formValueForFiltering], option__query);

            return commonUtils.validFilteredFormValue(filteredFormValue);
        });

        //console.log('[filteredOptions]', filteredOptions);

        return filteredOptions;
    },
    filterer_getValue: function filterer_getValue(rootObject, path) {
        //this.registerKeypathToWatch(path); //# don't need to register since we register onReduxInit

        var formattedPath = this.formattedKeypathForKeypath(path);

        //console.log('[filterer_getValue()] at path:', path, rootObject, formattedPath);
        return _.get(rootObject, formattedPath);
    },
    filterer_selectFields: function filterer_selectFields(fields) {
        var _this10 = this;

        return function (obj) {
            var result = {};
            var key;

            for (var i = 0; i < fields.length; i++) {
                key = fields[i];

                var gotValue = _this10.filterer_getValue(obj, key);

                if (typeof gotValue != 'undefined') {
                    result[key] = gotValue;
                }
            }
            return result;
        };
    },
    cachePromisedOnceResolved: function cachePromisedOnceResolved(promisedOnceResolved) {
        var _this11 = this;

        if (!promisedOnceResolved) {
            return false;
        }

        promisedOnceResolved.then(function () {
            //console.log("this.clearStatefulCacheForKey('promisedOnceResolved')", this.getID())
            _this11.clearStatefulCacheForKey('promisedOnceResolved');
        });

        this.setStatefulCacheForKey('promisedOnceResolved', promisedOnceResolved);
    },
    promisedOnceResolve: function promisedOnceResolve() {
        var promisedOnceResolved = this.getStatefulCacheForKey('promisedOnceResolved');

        /*
        promisedOnceResolved && promisedOnceResolved.then(() => {
            console.log('promisedOnceResolve() resolved for', this.getID())
        })
        */

        return promisedOnceResolved || Promise.resolve();
    }
    //# == TESTS ====================================//
    ,
    test: {
        data: IS_DEV_MODE ? require('./browser-test/data') : null,
        testSeed: function testSeed() {
            var rubyApp = undefined.getRoot();
            var store = undefined.getStore();
            store.dispatch(rubyApp.getActiveRouteComponent().getChildren()[0].getAction().generators.seedWithFormData(rubyApp.findDescendentBy(function (node) {
                return node.props.key == 'dynamicForm_simple';
            }).test.data));
        }
    }
}, displayValue));

module.exports = RCRubyComponentFieldDynamicForm;